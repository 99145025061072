<template>
  <div v-if="$root.homeApiCall">
    <div class="container-main">
      <div class="frils"><img src="images/frills.png" /></div>
      <div class="puroshkar" v-if="$root.rewards.length">
        <h2 class="pre-1"><img :src="$root.containImage.thumbnail_path" /></h2>
        <Title :title="$root.containImage.title" textShadow="font-shadow" />
        <separaror innerClass="seperator" />
        <div class="puroshkar-lists">
          <div class="puroshkar-slider-large">
            <div
              class="puroshkar-slider"
              v-for="reward in $root.rewards"
              :key="reward.id"
            >
              <img :src="reward.thumbnail_path" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
      href="https://www.facebook.com/groups/hullorbangladesh"
      style="text-decoration: none;"
      target="_blank"
    >
      <div style="text-align:center; padding-bottom:40px;">
        <h1 class="font-shadow" style="color:white; font-size:25px;">
          প্রাইজ মানি ও নতুন সব আপডেট পেতে
        </h1>
        <img src="images/Facebook_Icon.png" style="height:200px; width:200px" />
        <h1
          class="font-shadow"
          style="color:white; font-size:25px;text-decoration: none;"
        >
          লাইক দিন
        </h1>
      </div>
    </a>
    <!--    <separaror innerClass="sp-line1" v-if="$root.rules.length" />-->
    <div class="container-main" v-if="$root.rules.length">
      <div class="puroshkar">
        <h2 class="pre-1"><img :src="$root.gameRuleImage.thumbnail_path" /></h2>
        <Title :title="$root.gameRuleImage.title" textShadow="font-shadow" />
        <separaror innerClass="seperator" />
        <div class="gamerules">
          <div class="gamerules-slider-large">
            <div
              class="gamerules-slider"
              v-for="rule in $root.rules"
              :key="rule.id"
            >
              <img :src="rule.thumbnail_path" />
              <!--              <h4>{{ rule.serial }} <br />{{ rule.title }}</h4>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <separaror innerClass="sp-line1" v-if="$root.rules.length" />-->
  </div>
</template>

<script>
import slider from "@/js/slider";
import Separaror from "@/components/Separaror";
import Title from "@/components/Title";

export default {
  name: "GameSlider",
  components: {
    Separaror,
    Title
  },
  data: () => {
    return {
      contentImage: null,
      ruleText: null,
      rewardSliderImages: [],
      rulesImages: null,
      rewardText: null,
      ruleSliderImages: []
    };
  },
  created() {
    this.slider();
  },
  mounted() {
    this.slider();
  },
  updated() {
    this.slider();
  },
  methods: {
    slider() {
      var $jq = jQuery.noConflict();
      $jq(document).ready(function() {
        let sliderConfig = slider.sliderConfig;
        $jq(".puroshkar-slider-large").slick(sliderConfig);
        $jq(".gamerules-slider-large").slick(sliderConfig);
        $jq(".testi-slider-large").slick(sliderConfig);
      });
    }
  }
};
</script>

<style scoped></style>
